






































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'Question506',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        titrationCurve1: null,
        titrationCurve2: null,
        titrationCurve3: null,
        titrationCurve4: null,
      },
      graphLabel: ['a)', 'b)', 'c)', 'd)'],
      options: [
        {text: 'Strong acid titrated with a weak base', value: 'SAwWB'},
        {text: 'Strong acid titrated with a strong base', value: 'SAwSB'},
        {text: 'Strong base titrated with a weak acid', value: 'SBwWA'},
        {text: 'Strong base titrated with a strong acid', value: 'SBwSA'},
        {text: 'Weak base titrated with a strong acid', value: 'WBwSA'},
        {text: 'Weak acid titrated with a strong base', value: 'WAwSB'},
      ],
      inputName: ['titrationCurve1', 'titrationCurve2', 'titrationCurve3', 'titrationCurve4'],
    };
  },
  computed: {
    imagePrefix(): string {
      return `/img/assignments/titrationCurve_Type`;
    },
    versionNumber(): number {
      return (this as any).taskState.getValueByName('versionNumber').content.value ?? 1;
    },
    typeA(): string {
      if (this.versionNumber >= 1 && this.versionNumber <= 6) {
        return '1';
      } else if (this.versionNumber >= 6 && this.versionNumber <= 9) {
        return '2';
      } else if (this.versionNumber == 10) {
        return '3';
      } else {
        return '1';
      }
    },
    typeB(): string {
      if (this.versionNumber >= 1 && this.versionNumber <= 3) {
        return '2';
      } else if (
        this.versionNumber == 4 ||
        this.versionNumber == 5 ||
        this.versionNumber == 7 ||
        this.versionNumber == 8
      ) {
        return '3';
      } else if (this.versionNumber == 6 || this.versionNumber == 9 || this.versionNumber == 10) {
        return '4';
      } else {
        return '2';
      }
    },
    typeC(): string {
      if (this.versionNumber >= 1 && this.versionNumber <= 3) {
        return '3';
      } else if (
        this.versionNumber == 4 ||
        this.versionNumber == 5 ||
        this.versionNumber == 7 ||
        this.versionNumber == 8
      ) {
        return '4';
      } else if (this.versionNumber == 6 || this.versionNumber == 9 || this.versionNumber == 10) {
        return '5';
      } else {
        return '3';
      }
    },
    typeD(): string {
      if (this.versionNumber == 1) {
        return '4';
      } else if (this.versionNumber == 2 || this.versionNumber == 4 || this.versionNumber == 7) {
        return '5';
      } else if (
        this.versionNumber == 3 ||
        this.versionNumber == 5 ||
        this.versionNumber == 6 ||
        this.versionNumber >= 8
      ) {
        return '6';
      } else {
        return '4';
      }
    },
    titrationCurveImages(): string[] {
      return [
        this.imagePrefix + this.typeA + '.png',
        this.imagePrefix + this.typeB + '.png',
        this.imagePrefix + this.typeC + '.png',
        this.imagePrefix + this.typeD + '.png',
      ];
    },
  },
});
